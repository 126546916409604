import * as React from "react"
import { useState, useEffect} from "react"
import PropTypes from "prop-types"
import "../styles/animatedHeading.css"
import { nanoid } from 'nanoid'

const getLetters = ( element) => {
	
	let ret = []
	
	if( Array.isArray(element)){
		
		for( let i = 0; i < element.length; i++){
			
			
			if( !Array.isArray( element[i]) && typeof element[i] === "object" && element[i].props.children !== "undefined"){
				
				ret.push( ...getLetters( element[i].props.children))
			
			}
			
			if( typeof element[i] === "string"){
				
				for( let j = 0; j < element[i].length; j++){
					
					ret.push( element[i][j])
				}
			}
		}
	}
	
	if( typeof element === "string"){
				
		for( let j = 0; j < element.length; j++){
			
			ret.push( element[j])
		}
	}	
	
	return ret
}

const AnimatedLetter = ({ className, animation, delay, duration, letter}) => {
	
	if( animation.length < 2)
		throw new Error( "AnimationLetter needs atleast begin and end stage of animation")
		
	const [ currentStyle, setCurrentStyle] = useState( { ...animation[0]})
		
	useEffect(() => {
		
		const timeout = setTimeout( () => {
			setCurrentStyle( { ...animation[1]})
		}, delay)
		
		return () => clearTimeout( timeout)
	}, [ delay, animation]);
		
	return(
		<span style={ { transition: `all ${duration}ms 0ms ease`, ...currentStyle}} className={ `kn-animated-letter ${className}`}  >
			{ letter}
		</span>			
	)
}

AnimatedLetter.propTypes = {
	className: PropTypes.string,
	animation: PropTypes.array.isRequired,
	delay: PropTypes.number,
	duration: PropTypes.number,
	letter: PropTypes.string,
}

AnimatedLetter.defaultProps = {
	className: ``,
	delay: 1,
	duration: 1,
	letter: ``,
}

const AnimatedHeading = React.memo( ({ className, delay, delayBetween, duration, animation, children }) => {
	
	const headingType = children.type
	
	const letters = getLetters( children.props.children)
	
	return (
		<div className={`kn-animated-heading-wrapper`}  >
			{ React.createElement( headingType, { ...children.props, className: `kn-animated-heading ${className}`}, letters.map( ( letter, key) => <AnimatedLetter key={nanoid()} animation={animation} delay={ parseInt( delay + delayBetween*key)} duration={ duration} letter={letter} />))}
		</div>		
	)
})

AnimatedHeading.propTypes = {
	className: PropTypes.string,
	delay: PropTypes.number,
	delayBetween: PropTypes.number,
	duration: PropTypes.number,
	animation: PropTypes.array.isRequired,
	children: PropTypes.node.isRequired,
}

AnimatedHeading.defaultProps = {
	className: ``,
	delay: 0,
	delayBetween: 40,
	duration: 500,
	animation: [{opacity:0, transform:"translateY(-50px)"},{opacity:1,transform:"translateY(0px)"}],
}

export default AnimatedHeading
