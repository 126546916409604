import React from "react"

import "../styles/slide.css"

export default function Slide({ index, width, activeSlide, backgroundImage, children}) {
	
	return (
		<div style={{ width: width}} className={`kn-slide${( activeSlide === index ? ' kn-active-slide' : '')}`} >
		
			<img className="kn-slide-image" src={ backgroundImage} alt="pozadina"/>
			
			<div className="kn-slide-content">
				{ children}
			</div>
		</div>
	)
}