import React from "react"
import Header from "./Header"
import Footer from "./Footer"
import Head from "./Head"

import CookieConsent from 'react-cookie-consent'
import AniLink from "gatsby-plugin-transition-link/AniLink"

import "../styles/global.css"

export default function Layout( { title,children}) {
	
	return (
		<div className="kn-layout">

			<Head title={title}>
				<script src="https://kit.fontawesome.com/ab9bc49da2.js" crossorigin="anonymous"></script>
				<script async src="https://www.googletagmanager.com/gtag/js?id=G-04CEDLWP0P"></script>
				<script dangerouslySetInnerHTML={{ __html: "window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);}; gtag('js', new Date()); gtag('config', 'G-04CEDLWP0P');" }}></script>
			</Head>
			
			<Header />
			
			<main className="kn-content">
				{children}
			</main>
			
			<Footer />
			
			<CookieConsent location="bottom" buttonText="Prihvaćam" declineButtonText="Odbijam" cookieName="gatsby-gdpr-google-analytics">
				IZJAVA O ZAŠTITI PRIVATNOSTI I SIGURNOSTI OSOBNIH PODATAKA (<AniLink fade to="/pravila-privatnosti"> Saznajte više</AniLink>)
			</CookieConsent>
		</div>
	)
}
