import React from "react"

import { Link} from "gatsby"

import AniLink from "gatsby-plugin-transition-link/AniLink"

import { isActive} from "../utils/Utils"

import "../styles/navigationMenu.css"

const KnActiveLink = ( props ) => ( 
	<AniLink cover bg="linear-gradient(60deg, #29323c 0%, #485563 100%)" to={props.to} id={ props.id} getProps={isActive} {...props} />
)


export default function NavigationMenu({ isFooter}) {
  return (
	<nav className="kn-nav">
		<div className={`kn-nav-container ${ isFooter ? "kn-nav-container-footer" : ""}`}>
			<KnActiveLink to="/">Početna</KnActiveLink>
			<Link to="#o-nama">O nama</Link>
			<Link id="usluge" to="#usluge">Usluge</Link>
			<Link to="#kontakt">Kontakt</Link>
			{ isFooter ? <KnActiveLink to="/pravila-privatnosti">Pravila privatnosti</KnActiveLink> : <></>}
		</div>
	</nav>
	)
}