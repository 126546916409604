import React from "react"
import { Helmet } from "react-helmet"

export default function Head( { title, children}) {
  return (
	<Helmet>
		<meta charset="utf-8" />
		<title>{title}</title>
		{children}
	</Helmet>
  )
}